<template>

  <el-dialog :show-close="false"	 custom-class="minWidthLandAdd" :title="form.title" :visible.sync="visible" append-to-body :close-on-click-modal="false"  draggable>
    <div v-if="form.type==0">
    <div style="text-align: left">
      <div style="text-align:center">
        <p>拍卖/挂牌交易需委托德阳市当地土地交易机构进行；同意委托后才可继续</p>
      </div>

    </div>
    <div></div>
    <div style="text-align: center; padding-top:10px ">
      <el-checkbox style="color: black"  placeholder="请勾选协议" v-model="form.verify_ckb" @change="changeErr()"></el-checkbox>
      我已阅读并同意遵守<span style="color: #1e88fb" @click="form.type=1;form.title='德阳市建设用地使用权转让、出租、抵押二级市场管理办法（试行）'">《德阳市建设用地使用权转让、出租、抵押二级市场管理办法（试行）》</span>和<span style="color: #1e88fb"  @click="form.type=2;form.title='德阳市建设用地超市'" >《德阳市建设用地使用权转让、出租、抵押二级市场交易规则》</span>
    </div>
    <div style="margin-left: 144px"><el-alert v-show="errorShow" type="error" :closable="false" title="请勾选协议"></el-alert></div>


    </div>
    <div v-if="form.type==1">
    <div style="text-align: left">
      <div style="line-height: 30px;">
          <p style="text-align: center; font-size: 20px; font-weight: bold">第一章 总则</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第一条 为进一步规范我市建设用地使用权转让、出租、抵押二级市场秩序，加强市场监管，优化土地资源配置，根据《中华人民共和国民法典》《中华人民共和国土地管理法》《中华人民共和国城市房地产管理法》《中华人民共和国城镇国有土地使用权出让和转让暂行条例》《国务院办公厅关于完善建设用地使用权转让、出租、抵押二级市场的指导意见》（国办发〔2019〕34号）《四川省人民政府办公厅关于完善建设用地使用权转让、出租、抵押二级市场的实施意见》（川办发〔2020〕41号）等规定，结合我市实际，制定本办法。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二条 德阳市范围内建设用地使用权转让、出租、抵押适用本办法。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三条 建设用地使用权转让、出租、抵押应遵循依法依规、诚实守信、公平自愿的原则。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第四条 建设用地使用权转让、出租、抵押二级市场的交易对象是国有建设用地使用权，重点针对土地交易以及土地连同地上建筑物、构筑物及其附属设施等整宗地一并交易的情况。涉及房地产交易的，应当遵守《中华人民共和国城市房地产管理法》《城市房地产开发经营管理条例》等法律法规规定。</p>
      <p></p>
        <p style="text-align: center; font-size: 20px; font-weight: bold">第二章 建设用地使用权转让</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第五条 本办法所称建设用地使用权转让，是指土地使用权人将依法取得的建设用地使用权和地上建筑物、构筑物及其附属设施所有权一并转移的行为，包括买卖、交换、赠与、出资以及司法处置、资产处置、法人或其他组织合并或分立等各类导致建设用地使用权转移的行为。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第六条 建设用地使用权具有以下情形之一的，不得转让：</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）未取得不动产权证书的；</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）按照建设用地使用权出让合同约定进行投资开发，属于房屋建设工程的，未完成开发投资总额25%的，属于成片开发土地，未形成工业用地或者其他建设用地条件的；</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（三）司法机关和行政机关依法裁定、决定查封或者以其他形式限制房地产权利的；</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（四）共有建设用地使用权，未经其他共有人书面同意的；</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（五）不符合建设用地使用权有偿使用合同等约定的；</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（六）法律、法规规定禁止转让的其他情形。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第七条 以出让方式取得的建设用地使用权转让，由转让人提供具有专业资质的审计机构出具的已投资额达到开发投资总额25%的书面审计意见。已投资额和开发投资总额均不含建设用地使用权出让价款和向国家缴纳的相关税费。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第八条 以出让方式取得的建设用地使用权转让，未完成开发投资总额25%的，按照“先投入后转让”的原则，在交易双方签订建设用地使用权转让合同后，允许依法办理预告登记，待开发投资达到转让条件时，再办理不动产转移登记手续。预告登记权利人可以凭不动产预告登记证明及其他必要材料向发改、自然资源、住建、生态环境等部门申请办理建设项目相关手续。预告登记后，债权消灭或者自能够进行不动产登记之日起3个月内未申请登记的，预告登记失效。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第九条 以划拨方式取得的建设用地使用权转让，需经有批准权的市、区（市、县）政府批准同意。土地用途符合《划拨用地目录》的，经市、区（市、县）政府批准同意，可不补缴土地出让价款，仍保留土地划拨性质，按转移登记办理；不符合《划拨用地目录》的，在符合规划的前提下，由受让方依法依规办理土地使用权出让手续，补缴土地出让价款。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十条 以划拨方式取得的建设用地使用权转让,不改变土地用途和规划条件，经批准直接转为出让的，原则上按照拟出让时的出让土地使用权评估市场价扣减拟出让时原划拨土地使用权评估权益价确定补缴土地出让价款。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十一条 以作价出资或入股方式取得的建设用地使用权转让，参照以出让方式取得的建设用地使用权转让有关规定，不再报经原批准建设用地使用权作价出资或入股的机关批准；转让后，可保留为作价出资或入股方式，或直接变更为出让方式。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十二条 以租赁方式取得的建设用地使用权转让，承租人投入开发建设的资金达到工程建设总投资的25%以后，经出租人同意，承租人可以转让租赁的建设用地使用权。原租赁合同对转租条件另有约定的，从其约定。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十三条 建设用地使用权转让时，原土地有偿使用合同、相关登记文件等载明的权利与义务随之转移，受让人应依法履约。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十四条 建设用地使用权转让后，建设用地使用年限为原土地有偿使用合同规定的使用年限减去原土地使用者已使用年限后的剩余年限。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十五条 转让中涉及原土地与房屋产权证书记载用途不一致的，原则上应依据国土空间规划统一用途，在完善相关手续后予以办理不动产登记；确实无法统一的，在办理不动产登记时，可按照原证书登记用途分别注明，但未经批准不得改变已批准用途。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十六  标定地价覆盖区域土地交易价格比标定地价低20%以上的，标定地价未覆盖区域交易价格比同类型土地市场价格低20%以上的，市、区（市、县）政府可以行使优先购买权。</p>
        <p style="text-align: center; font-size: 20px; font-weight: bold">第三章 建设用地使用权出租</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十七条 本办法所称建设用地使用权出租，是指土地使用权人作为出租人将建设用地使用权随同地上建筑物、构筑物及其附属设施出租给承租人使用，由承租人向出租人支付租金的行为。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十八条 以出让、租赁、作价出资或入股等有偿方式取得的建设用地使用权出租或转租的，不得违反法律法规和有偿使用合同的相关约定。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第十九条 以划拨方式取得的建设用地使用权出租的，应按照有关规定上缴租金中所含土地收益，纳入土地出让收入管理。建立划拨建设用地使用权出租收益年度申报制度，出租人须在出租合同生效后3个月内，向税务部门依法申报并缴纳相关收益。划拨建设用地使用权出租年收益缴纳标准暂定按建设用地面积×基准地价水平的1%缴纳，不足一年的，按月收取。划拨建设用地使用权出租收益缴纳标准及征缴办法由市、区（市、县）政府依法确定。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十条 以划拨方式取得的建设用地使用权长期出租，或部分用于出租且可分割的，划拨建设用地使用权人应依法补办出让、租赁等有偿使用手续。</p>
        <p style="text-align: center; font-size: 20px; font-weight: bold">第四章 建设用地使用权抵押</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十一条 自然人、企业均可作为抵押权人申请以建设用地使用权及地上建筑物、构筑物及其附属设施所有权办理不动产抵押相关手续，涉及企业之间债权债务合同的须符合有关法律法规的规定。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十二条 以租赁方式取得的建设用地使用权，承租人在按规定支付土地租金并完成开发建设后，根据租赁合同约定，其地上建筑物、构筑物及其附属设施连同土地可以依法一并抵押。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十三条 营利性的教育、医疗、养老等社会领域企业可将有偿取得的建设用地使用权、设施等财产进行抵押融资。抵押权实现后，应保障原有经营活动持续稳定，确保土地用途不改变、利益相关人权益不受损。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十四条 划拨土地抵押权实现时，从拍卖所得的价款中扣除应缴纳的土地价款后，抵押权人方可受偿。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第五章 建设用地使用权分割、合并</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十五条 宗地分割、合并转让，应有利于土地开发利用。拟分割、合并的宗地应当权属清晰，且已依法办理不动产登记。分割、合并后的地块应具备独立分宗条件，符合规划、设计、建设、施工以及安全等要求，不影响生产、经营和使用功能。拟分割宗地已预售或存在多个权利主体的，应取得相关权利人书面同意。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十六条 涉及产业功能区内工业厂房分割转让的，按照《德阳市自然资源和规划局德阳市住房和城乡建设局德阳市经济和信息化局关于产业功能区内工业厂房分割转让及办理不动产登记有关事项的通知》（德自然资发〔2021〕205号）执行。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十七条 宗地合并时，合并前使用权类型为划拨且合并后宗地用途符合划拨用地目录的，土地使用权人可选择保留划拨方式。使用权类型包含划拨、出让、作价出资或入股等多种类型的，原则上统一办理为出让。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十八条 在不超过宗地合并后土地用途法定最高年限的前提下，根据土地使用权人意愿，可按照合并前任一宗地的终止日期修正土地出让年限；除工业用地外，也可以申请续期至法定最高出让年限。</p>
        <p style="text-align: center; font-size: 20px; font-weight: bold">第六章 交易市场建设与监督</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二十九条 市自然资源主管部门牵头，会同市政务和大数据局、成都农交所德阳所等单位，搭建全市城乡统一的土地二级市场交易平台，市、区（市、县）自然资源主管部门使用全市城乡统一的土地二级市场交易平台，汇集全市土地二级市场交易信息。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十条 市、区（市、县）自然资源主管部门依法对土地二级市场进行管理和监督。市自然资源主管部门具体承办市中心城区事权范围内土地二级市场交易相关工作；区（市、县）自然资源主管部门具体承办各区（市、县）事权范围内土地二级市场交易相关工作。发改、财政、住建、国资、司法、税务、市场监管、金融等部门应各司其职，密切配合，共同做好土地二级市场管理工作。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十一条 土地二级市场实行“线上+线下”交易模式，按照“信息发布—达成意向—签订合同—交易监管”的基本流程进行交易。交易双方可通过土地二级市场交易平台等渠道发布和获取市场信息。交易双方自行协商达成交易意向，或委托土地二级市场交易平台公开交易，并依法申报交易价格。自然资源主管部门要加强交易服务，鼓励和引导市场主体进入土地二级市场交易平台进行线上交易。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十二条 加强涉地司法处置工作衔接，建立涉地司法处置会商机制。法院生效裁判涉及建设用地使用权转移的案件，自然资源部门认为与现行土地管理等相关法律法规不一致的，应当向法院出具书面建议意见书，如法院仍要求执行生效裁判的，按法院的生效裁判执行。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十三条 政府有关部门、企事业单位进行国有资产处置时涉及划拨建设用地使用权转移的，处置前应征求同级自然资源主管部门意见，自然资源主管部门应将宗地有关情况如实告知。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十四条 自然资源主管部门要健全完善公示地价体系，定期更新和发布标定地价，为政府行使优先购买权、交易税收征缴、监测调控市场价格等提供依据。完善土地二级市场的价格形成、监测、指导、监督机制，防止交易价格异常波动。强化土地一、二级市场联动管理，加强土地投放数量、结构、时序等的衔接，适时运用财政、金融等手段，加强对土地市场的整体调控，维护市场平稳运行。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十五条 逐步引入社会中介组织，为交易各方提供推介、展示、咨询、估价、经纪等服务，发挥社会中介组织在市场交易活动中的桥梁作用。行业主管部门要加强对社会中介组织的指导和监管，引导其诚信经营。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十六条 自然资源主管部门会同发改、政务和大数据等部门制定土地市场信用评价标准和约束措施，将有偿使用合同或划拨决定书履约和执行、划拨建设用地使用权人出租收益年度申报等情况纳入信用管理，相关市场主体失信信息归集推送至省社会信用信息平台，并通过“国家企业信用信息公示系统（四川）和信用中国（四川）”进行公示，对严重失信责任主体实施联合惩戒。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十七条 各有关部门（单位）要严格执行土地二级市场相关规定，坚决打击弄虚作假、损害土地使用权人利益、违反相关规定破坏土地市场秩序的行为，严防各种腐败行为，严格实行责任追究，确保土地二级市场规范有序。</p>
        <p style="text-align: center; font-size: 20px; font-weight: bold">第七章 附则</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十八条 已依法入市的集体经营性建设用地使用权转让、出租、抵押，可参照本办法执行。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三十九条 本办法未尽事宜，按照国家法律法规、相关政策规定办理。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第四十条 本办法自2023年2月1日起施行，有效期2年。</p>


        <p style="text-align: center">

				<el-button  type="primary"  @click="form.type=0;form.title='网上交易须知'">确认</el-button>

        </p>
      </div>
    </div>
    <template>

    </template>
    </div>

    <div v-if="form.type==2">
      <div style="text-align: left">
        <div style="line-height: 30px;">
          <p style="text-align: center; font-size: 20px; font-weight: bold">敬请期待.....</p>

        </div>
        <p style="text-align: center">

          <el-button  type="primary"  @click="form.type=0;form.title='网上交易须知'">确认</el-button>

        </p>
      </div>
    </div>
    <template #footer v-if="form.type==0" >

			<span class="dialog-footer">
		<span class="dialog-footer">
				<el-button @click="onClose(2)">取消</el-button>
				<el-button type="primary" @click="onSubmit">确认</el-button>
			</span>
			</span>
    </template>
  </el-dialog>

</template>

<script setup  name="needAdd">
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'




// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const errorShow = ref(false);
const loading = ref(false);
const callbackFunc = ref(function () {

});


const form = reactive({
  username: "",
  verify_ckb:"",
  title:"网上交易须知",
  type:0
})

const onClose= (id) =>{
  callbackFunc.value(false);
  visible.value = false;
}
const changeErr = () =>{
  if (form.verify_ckb) {
    errorShow.value = false;
    return;
  }
};

// 打开弹窗
const openDialog = (address, geoInfo, callback) => {
  form.type=0;form.title='网上交易须知'
  visible.value = true;

  callbackFunc.value = callback;
  form.verify_ckb='';
  nextTick(() => {
    dataFormRef.value?.resetFields();
  });
  // 获取landInfo信息
};


const onSubmit = () => {
  if (!form.verify_ckb) {
    errorShow.value = true;
    return;
  }
  if (callbackFunc.value) {
    callbackFunc.value(form.verify_ckb);
  }
  visible.value = false;
}




const forms = reactive({
  timer:null,
  userverify:false
})

const closeDialog = () => {
  visible.value = false;
};
const setType = (val) => {
  visible.value = false;
};
// 暴露变量
defineExpose({
  openDialog,
  setType,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}

</style>
